<template>
    <div class="container">

        <!--        <Header v-if="loc === '/'"/>-->
        <!--        <Header2 v-else/>-->

        <transition name="fade" mode="out-in">
            <router-view/>
        </transition>

        <Footer/>
        <Scrolltop/>
    </div>
</template>

<script>
// import Header from '../components/Header-1';
// import Header2 from '../components/Header-2';

import Footer from '../components/Footer';
import Scrolltop from '../components/Scrolltop';

export default {
    components: { Scrolltop, Footer },
    // components: { Header2, Header },
    metaInfo: {
        titleTemplate: '%s - Xeoscript Technologies',
        htmlAttrs: {
            lang: 'en',
            amp: true
        }
    }
    // name: 'BaseLayout',
    // data () {
    //     return {
    //         loc: ''
    //     };
    // },
    // methods: {
    //     currentLocation () {
    //         this.loc = window.location.pathname;
    //         console.log('Location =>', this.loc);
    //     }
    // },
    // beforeMount () {
    //     this.currentLocation();
    // }
};
</script>

<style>
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}
</style>
