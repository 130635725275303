<template>
    <footer>
        <div class="xeo-section2-container-fluid xeo-foot-bg">
            <div class="xeo-container xeo-container-mob-padding">
                <div class="xeo-foot-container clearfix">
                    <div class="xeo-navigation">
                        <p>Navigation</p>
                        <ul>
                            <li>
                                <router-link to="/">Home</router-link>
                            </li>
                            <li>
                                <router-link to="/work/">Work</router-link>
                            </li>
                            <li>
                                <router-link to="/about/">About</router-link>
                            </li>
                            <li>
                                <router-link to="/contact/">Contact</router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="xeo-social">
                        <p>Services</p>
                        <ul>
                            <li>
                                <router-link to="/bigdata/">Big data analysis</router-link>
                            </li>
                            <li>
                                <router-link to="/business-intelligence-tools/">Business Intelligence
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/modern-web-development/">Modern web development
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/mobile-app-development/">Mobile app development
                                </router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="xeo-contact">
                        <p>Contact Us</p>
                        <ul>
                            <li>
                                First Floor, Kalarikkal Building, Mannuthy ByPass, Mannuthy P O, Thrissur, PIN: 680 651
                            </li>
                            <li>
                                <a href="tel:+91 9037692526">+91 9037692526</a><br>
                                <a href="tel:+91 9961989989">+91 9961989989</a><br>
                                <a href="mailto:info@xeoscript.com">info@xeoscript.com</a>
                            </li>
                        </ul>
                    </div>
                    <div class="xeo-form">

                        <transition name="fade" mode="out-in">

                            <div class="alert alert-success mb-0 w-100 text-center my-5" v-if="state === 'S'"
                                 key="'S">
                                <p class="my-5">The mail has been successfully submitted.</p>
                            </div>

                            <div class="alert alert-danger mb-0 w-100 text-center my-5"
                                 v-else-if="state === 'F'" key="'F">
                                <p class="my-5">The mail delivery has been failed.</p>
                            </div>

                            <div class="alert alert-info mb-0 w-100 text-center my-5" v-else-if="state === 'L'"
                                 key="'L">
                                <p class="my-5">Please wait while communicate with the server</p>
                            </div>

                            <form class="reservation-form" v-else @submit.prevent="onSubmit">
                                <ul>
                                    <li><input name="name" v-model="name" type="text" id="name" placeholder="Name" required></li>
                                    <li><input name="email" v-model="email" type="email" id="email" placeholder="Email" required></li>
                                    <li><input name="subject" v-model="subject" type="text" id="subject" placeholder="Subject" required>
                                    </li>
                                    <li><textarea name="message" v-model="message" id="message" placeholder="Message "
                                                  required></textarea>
                                    </li>
                                    <li>
                                        <button type="submit" name="submit">Send</button>
                                    </li>
                                </ul>
                            </form>
                        </transition>
                    </div>
                    <div class="xeo-map-location">
                        <div class="xeo-map">
                        </div>
                        <div class="xeo-link">
                        </div>
                    </div>
                </div>
            </div>
            <div class="xeo-footer-copyright">
                <p>&copy; Xeoscript Technologies 2018 - All Rights Reserved</p>
            </div>
        </div>
    </footer>
</template>

<script>
import axios from 'axios';

export default {
    name: 'Footer',
    data () {
        return {
            state: 'D',
            name: '',
            email: '',
            message: '',
            subject: ''
        };
    },

    methods: {
        onSubmit () {
            const bodyFormData = new FormData();
            bodyFormData.append('name', this.name);
            bodyFormData.append('email', this.email);
            bodyFormData.append('message', this.message);
            bodyFormData.append('consent', this.subject);

            const that = this;

            this.state = 'L';

            axios({
                method: 'post',
                url: 'https://xeo.xeoscript.com/footermail.php',
                data: bodyFormData,
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then(function (response) {
                that.onSubmitSuccess(response);
            }).catch(function (response) {
                that.onSubmitFailed(response);
            });
        },
        onSubmitSuccess () {
            this.state = 'S';
        },

        onSubmitFailed () {
            this.state = 'F';
        }
    },
    mounted () {
        const externalScript = document.createElement('script');
        externalScript.setAttribute('src', 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCLcHUv_0BgUju3PzVfJdq6BmjXoMH2Gi8&callback=myMap');
        document.head.appendChild(externalScript);
    }
};
</script>
<style scoped>

</style>
